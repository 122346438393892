import header1 from "./assets/header-1.jpg";
import header2 from "./assets/header-2.jpg";
import product1 from "./assets/product-1.jpeg";
import product2 from "./assets/product-2.jpeg";
import product3 from "./assets/product-3.jpeg";
import product4 from "./assets/product-4.jpeg";
import product5 from "./assets/product-5.jpeg";
import product6 from "./assets/product-6.jpeg";
import product7 from "./assets/product-7.jpeg";
import product8 from "./assets/product-8.jpeg";
import product9 from "./assets/product-9.jpeg";
import product10 from "./assets/product-10.jpeg";
import video1 from "./assets/video-1.mp4";
import video2 from "./assets/video-2.mp4";

export const headerImages = [
  {
    id: 1,
    image: header1,
  },
  {
    id: 2,
    image: header2,
  },
];

export const productImages = [
  { id: 1, video: video1 },
  { id: 2, video: video2 },
  { id: 3, image: product1, title: "Title here" },
  { id: 4, image: product2, title: "Title here" },
  { id: 5, image: product3, title: "Title here" },
  { id: 6, image: product4, title: "Title here" },
  { id: 7, image: product5, title: "Title here" },
  { id: 8, image: product6, title: "Title here" },
  { id: 9, image: product7, title: "Title here" },
  { id: 10, image: product8, title: "Title here" },
  { id: 11, image: product9, title: "Title here" },
  { id: 12, image: product10, title: "Title here" },
];
