import { useRef } from "react";
import aboutImg from "../assets/about.jpg";
import { motion, useInView } from "framer-motion";

const AboutUs = () => {
  const ref = useRef();
  const inView = useInView(ref, { amount: 0.5 });

  return (
    <div className="py-8 relative">
      <h1 className="text-mainGreen underline text-3xl text-center md:text-4xl font-semibold mb-2">
        About Us
      </h1>

      <div className="mx-4 md:w-[85vw] xl:w-[70vw] py-6 md:py-16 md:mx-auto relative z-10">
        <div
          ref={ref}
          className="grid md:grid-rows-1 md:grid-cols-2 justify-center gap-5"
        >
          <motion.div
            initial={{ x: "-400px", opacity: 0 }}
            animate={inView && { x: "0px", opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <img
              src={aboutImg}
              alt="about"
              className="md:h-[90vh] object-cover rounded-lg w-full"
            />
          </motion.div>
          <motion.div
            className="text-center text-lg "
            initial={{ x: "400px", opacity: 0 }}
            animate={inView && { x: "0px", opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <h1 className="font-semibold text-2xl mb-4 text-mainGreen">
              Bubola and Naibo
            </h1>
            <p className="mb-4">
              Welcome to Bubola & Naibo, your premier destination for framing,
              art, and wall decor! Here at Bubola& Naibo, we pride ourselves on
              our meticulous attention to detail and commitment to excellence in
              craftsmanship.
            </p>
            <p className="mb-4">
              Our specialty lies in crafting custom frames tailored precisely to
              your measurements, ensuring a perfect fit for your cherished
              memories and artwork. Utilizing only the finest materials
              available, we guarantee durability and aesthetic appeal in every
              piece we create.
            </p>
            <p className="mb-4">
              With many quality mouldings and frames made from plantation-grown
              natural woods, we offer a diverse range of styles to suit every
              taste and preference. Whether you prefer classic elegance, modern
              minimalism, or something in between, we have the perfect frame to
              complement your unique aesthetic.
            </p>
            <p>
              At Bubola& Naibo, we understand the importance of showcasing your
              memories and artwork with care and sophistication. That's why our
              team of skilled craftsmen is dedicated to providing personalized
              service and expert guidance every step of the way. From selecting
              the ideal frame to perfecting the finishing touches, we're here to
              bring your vision to life on your walls.
            </p>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
