import { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { IoMdClose } from "react-icons/io";
import { productImages } from "../data";
import ReactPlayer from "react-player";

const ProductsPage = () => {
  const [activeImage, setActiveImage] = useState(null);
  const [activeImageIndex, setActiveImageIndex] = useState(null);
  const [isHover, setIsHover] = useState(false);
  const [hoverIndex, setHoverIndex] = useState(null);

  useEffect(() => {
    if (isHover) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "visible";
  }, [isHover]);

  return (
    <AnimatePresence>
      <main className="min-h-screen mb-16">
        {!!activeImage && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="w-screen h-screen fixed flex justify-center items-center z-50 bg-black top-0 left-0"
            onClick={() => {
              setActiveImage(null);
              setActiveImageIndex(null);
              setIsHover(false);
            }}
          >
            <div className="fixed top-5 right-5">
              <div className="w-10 h-10 bg-white rounded-full drop-shadow-lg flex justify-center items-center">
                <IoMdClose className="text-3xl" />
              </div>
            </div>
            <motion.div
              layoutId={`product-img-${activeImageIndex + 1}`}
              className="w-[800px]"
            >
              <img src={activeImage} className="w-[800px]" />
            </motion.div>
          </motion.div>
        )}
        <div className="text-center my-16">
          <h1 className="text-mainGreen underline text-2xl md:text-3xl font-semibold mb-2">
            OUR PRODUCTS
          </h1>
          <p className="tracking-widest opacity-60 md:text-lg px-3 md:px-0">
            Explore our products
          </p>
        </div>
        <section className="hd:w-[1920px] hd:mx-auto lg:mx-24">
          <div className="gallery">
            {productImages?.map((product, index) => {
              if (product.video) {
                return (
                  <ReactPlayer
                    url={product.video}
                    width="100%"
                    height="100%"
                    controls
                  />
                );
              } else {
                return (
                  <motion.div
                    onHoverStart={() => {
                      setHoverIndex(index);
                    }}
                    onHoverEnd={() => {
                      setHoverIndex(null);
                    }}
                    key={`${product.title}_${index + 1}`}
                    layoutId={`product-img-${index + 1}`}
                    className="cursor-pointer mb-3 bg-black relative"
                    onClick={() => {
                      setActiveImageIndex(index);
                      setActiveImage(product.image);
                      setIsHover(true);
                    }}
                  >
                    {/* {hoverIndex === index && (
                    <p className="w-full z-50 pointer-events-none text-center text-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                      {product.title}
                    </p>
                  )} */}
                    <motion.img
                      // whileHover={{ opacity: 0.5 }}
                      src={product.image}
                      className="w-full"
                    />
                  </motion.div>
                );
              }
            })}
          </div>
        </section>
      </main>
    </AnimatePresence>
  );
};

export default ProductsPage;
