import React, { useState } from "react";
import emailjs from "@emailjs/browser";

const inputClasses =
  "w-full border-[1px] border-green-600 p-2 md:p-4 lg:p-2 2xl:p-4 md:text-lg lg:text-base 2xl:text-lg mb-6";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    company: "",
    telephone: "",
    address: "",
    city: "",
    message: "",
  });

  const resetForm = () => {
    setFormData({
      name: "",
      email: "",
      company: "",
      telephone: "",
      address: "",
      city: "",
      message: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const templateParams = {
      from_name: formData.name,
      from_email: formData.email,
      from_company: formData.company,
      from_phone: formData.telephone,
      from_address: formData.address,
      from_city: formData.city,
      from_message: formData.message,
    };
    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_EMAILJS_PUBLIC_ID
      )
      .then(
        (response) => {
          resetForm();
          console.log("SUCCESS!", response.status, response.text);
        },
        (err) => {
          console.log("FAILED...", err);
        }
      );
  };

  return (
    <main className="min-h-screen mb-16">
      <section className="grid grid-cols-1 lg:grid-cols-2 justify-center py-6 lg:py-16 px-4 lg:px-24 xl:px-32 gap-y-10 gap-x-10 hd:w-[1920px] hd:mx-auto">
        <div className="lg:order-2">
          <h1 className="text-3xl md:text-4xl lg:text-3xl 2xl:text-4xl font-semibold mb-6">
            Get In Touch
          </h1>
          <p className="leading-5 text-bsm opacity-60">
            Need assistance? Get in touch with our team by phone or email.
          </p>
          <p className="leading-5 text-bsm opacity-60">
            We have a team of dedicated professionals
          </p>
          <p className="leading-5 text-bsm opacity-60">
            Made of the design and technical team and the executive sales team.
          </p>
          {/* <div className="my-8">
            {contactDetails.map((item) => {
              return (
                <div key={item.id}>
                  <h1 className="text-lg md:text-xl lg:text-lg 2xl:text-xl font-bold my-4">
                    {item.title}
                  </h1>
                  <div className="flex items-center gap-4">
                    <div className="bg-green-600 p-2.5 rounded-full text-white">
                      {item.icon("md:text-lg lg:text-base 2xl:text-lg")}
                    </div>
                    {item.id === 2 || item.id === 3 ? (
                      <a
                        href={`mailto:${item.desc}`}
                        className="md:text-lg lg:text-base 2xl:text-lg font-medium"
                      >
                        {item.desc}
                      </a>
                    ) : (
                      <p className="md:text-lg lg:text-base 2xl:text-lg font-medium">
                        {item.desc}
                      </p>
                    )}
                  </div>
                </div>
              );
            })}
          </div> */}
        </div>
        <div className="bg-white shadow-custom px-4 xl:px-16 text-center">
          <form onSubmit={handleSubmit}>
            <div className="block md:flex md:gap-6">
              <input
                type="text"
                className={inputClasses}
                placeholder="NAME"
                value={formData.name}
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
              />
              <input
                type="email"
                className={inputClasses}
                placeholder="EMAIL"
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
              />
            </div>
            <div className="block md:flex md:gap-6">
              <input
                type="text"
                className={inputClasses}
                placeholder="COMPANY"
                value={formData.company}
                onChange={(e) =>
                  setFormData({ ...formData, company: e.target.value })
                }
              />
              <input
                type="number"
                className={inputClasses}
                placeholder="TELEPHONE"
                value={formData.telephone}
                onChange={(e) =>
                  setFormData({ ...formData, telephone: e.target.value })
                }
              />
            </div>
            <textarea
              className={inputClasses}
              rows="5"
              placeholder="MESSAGE"
              value={formData.message}
              onChange={(e) =>
                setFormData({ ...formData, message: e.target.value })
              }
            />
            <div className="block md:flex md:gap-6">
              <input
                type="text"
                className={inputClasses}
                placeholder="CITY"
                value={formData.city}
                onChange={(e) =>
                  setFormData({ ...formData, city: e.target.value })
                }
              />
              <input
                type="text"
                className={inputClasses}
                placeholder="ADDRESS"
                value={formData.address}
                onChange={(e) =>
                  setFormData({ ...formData, address: e.target.value })
                }
              />
            </div>
            <button
              type="submit"
              className="bg-green-600 p-2 md:p-4 lg:p-2 2xl:p-4 text-white text-2xl w-full"
            >
              Send Request
            </button>
          </form>
        </div>
      </section>
    </main>
  );
};

export default ContactUs;
