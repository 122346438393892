import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import { EffectFade, Autoplay } from "swiper/modules";
import { headerImages } from "../data";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <div className="h-[calc(100svh-112px)] relative">
      <div
        className="h-full w-full absolute top-0 left-0 z-40 flex flex-col items-center justify-center md:px-8 lg:px-16"
        style={{ background: "rgba(0,0,0,0.3)" }}
      >
        <h1 className="text-white text-4xl text-center block md:hidden">
          Framing, art, and wall decorator shop.
        </h1>
        <h1 className="text-white md:text-2xl lg:3xl text-center hidden md:block">
          Bubola & Naibo as an important reference point for wall decoration.
          Complete proposals for vertical furnishings. Mouldings and frames.
          Paintings and images. Modular furnishing systems
        </h1>
        <Link
          to="/products"
          className="bg-mainGreen px-3 py-2 mt-4 text-3xl rounded-lg text-white"
        >
          Our Products
        </Link>
      </div>
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        style={{ height: "100%" }}
        effect={"fade"}
        autoplay={{
          delay: 2500,
        }}
        speed="1000"
        modules={[EffectFade, Autoplay]}
      >
        {headerImages.map((item) => {
          return (
            <SwiperSlide key={item.id}>
              <img src={item.image} className="h-full w-full object-cover" />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default Header;
