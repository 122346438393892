import { useState } from "react";
import { IoMdMenu } from "react-icons/io";
import Sidebar from "./Sidebar";
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="h-28 bg-black">
      <div className="flex items-center justify-between h-full mx-4 md:mx-8">
        <Link to="/">
          <img
            src="https://www.bubolaenaibo.com/wp-content/themes/bubolaenaibo/images/logo_big.png"
            alt="logo"
            className="w-40"
          />
        </Link>
        <IoMdMenu
          className="text-white text-5xl block md:hidden"
          onClick={() => setIsOpen(true)}
        />
        <ul className="hidden md:flex md:justify-between md:items-center md:gap-8 text-white text-2xl">
          <li className="hover:text-green-600">
            <Link to="/">Home</Link>
          </li>
          <li className="hover:text-green-600">
            <Link to="/products">Products</Link>
          </li>
          <li className="hover:text-green-600">
            <Link to="/contact">Contact Us</Link>
          </li>
        </ul>
        <AnimatePresence>
          {isOpen && <Sidebar setIsOpen={setIsOpen} />}
        </AnimatePresence>
      </div>
    </nav>
  );
};

export default Navbar;
