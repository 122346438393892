import React from "react";
import { Link } from "react-router-dom";
import { IoMdClose } from "react-icons/io";
import { motion } from "framer-motion";
import { FaFacebook, FaInstagram, FaTwitter, FaPhoneAlt } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";

const showSidebar = {
  hidden: {
    x: 500,
  },
  visible: {
    x: 0,
    transition: {
      type: "tween",
      ease: "easeOut",
      when: "beforeChildren",
      staggerChildren: 0.1,
    },
  },
  exit: {
    x: -500,
    transition: {
      type: "tween",
    },
  },
};

const links = {
  hidden: {
    opacity: 0,
    x: -100,
  },
  visible: {
    opacity: 1,
    x: 0,
  },
};

const socials = {
  hidden: {
    opacity: 0,
    y: -100,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
};

const contacts = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
};

const image = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
};

const Sidebar = ({ setIsOpen }) => {
  return (
    <motion.div
      variants={showSidebar}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="w-screen bg-black fixed top-0 left-0 z-[999]"
      style={{ height: "100svh" }}
    >
      <IoMdClose
        className="absolute top-8 right-8 text-white text-4xl"
        onClick={() => setIsOpen(false)}
      />
      <div className="flex flex-col justify-start mt-44 items-center">
        <ul
          className="flex flex-col items-center justify-center gap-8 text-white text-2xl"
          onClick={() => setIsOpen(false)}
        >
          <motion.li variants={links} className="hover:text-green-600">
            <Link to="/">Home</Link>
          </motion.li>
          <motion.li variants={links} className="hover:text-green-600">
            <Link to="/products">Products</Link>
          </motion.li>
          <motion.li variants={links} className="hover:text-green-600">
            <Link to="/contact">Contact Us</Link>
          </motion.li>
          <div className="flex flex-col gap-4 pt-8">
            <motion.div
              variants={contacts}
              className="flex items-center justify-center gap-3"
            >
              <div>
                <IoMdMail className="text-green-600 text-3xl" />
              </div>
              <p className="text-white text-lg">bubolanaibo@gmail.com</p>
            </motion.div>
            <motion.div
              variants={contacts}
              className="flex items-center justify-center gap-3"
            >
              <div className="bg-green-600 p-1 rounded-md">
                <FaPhoneAlt className="text-black text-lg" />
              </div>
              <p className="text-white text-lg">+233 (0)244311309</p>
            </motion.div>
          </div>
        </ul>
        <div className="flex gap-8 text-3xl absolute bottom-8 text-green-600">
          <motion.div variants={socials}>
            <FaFacebook />
          </motion.div>
          <motion.div variants={socials}>
            <FaInstagram />
          </motion.div>
          <motion.div variants={socials}>
            <FaTwitter />
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
};

export default Sidebar;
